@import url("https://fonts.googleapis.com/css?family=Product+Sans");

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Product Sans", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(33, 31, 34);
    color: rgb(226, 133, 152);
}

div {
    background-color: rgb(33, 31, 34);
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.app {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.click-container {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
}

.menu {
    position: fixed;
    left: -20rem;
    top: 3rem;
    bottom: 0;
    width: 20rem;
    z-index: 5;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: null;
}

.menu.open {
    transform: translateX(20rem);
    box-shadow: 1rem 2rem 2rem 0 rgb(0, 0, 0, 0.75);
}

.menu > span {
    padding: 1rem;
    transition: all 0.3s;
}

.menu > span:hover {
    padding: 1rem;
    background-color: rgb(50, 50, 50);
}

.inner {
    max-width: 80rem;
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 5;
    height: 3rem;
    align-items: center;
    display: flex;
    box-shadow: 0 1rem 2rem 0 rgb(0, 0, 0, 0.75);
}

#logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#logo {
    width: 50rem;
    max-width: 100vw;
}

#menu {
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 1;
}

#menu > a {
    flex: 0 1 auto;
    width: 10rem;
    text-align: center;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
}

#about {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    text-align: center;
}

#about > * {
    max-width: 50rem;
    padding: 0.5rem;
}

.masonry {
    width: 100%;
    padding: 0.5rem;
    column-gap: 0;
}

@media screen and (min-width: 993px) {
    .masonry {
        column-count: 3;
    }
}

@media screen and (max-width: 992px) {
    .masonry {
        column-count: 2;
    }
}

@media screen and (max-width: 600px) {
    .masonry {
        column-count: 1;
    }
}

.masonry div {
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
    display: inline-block;
}

.masonry img {
    box-shadow: 0 0.5rem 1rem 0 rgb(0, 0, 0, 0.75);
    width: 100%;
    transition: transform 0.3s;
}

.masonry img:hover {
    transform: scale(1.1);
}
