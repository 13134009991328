@import url(https://fonts.googleapis.com/css?family=Product+Sans);
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Product Sans", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(33, 31, 34);
    color: rgb(226, 133, 152);
}

div {
    background-color: rgb(33, 31, 34);
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.app {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.click-container {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
}

.menu {
    position: fixed;
    left: -20rem;
    top: 3rem;
    bottom: 0;
    width: 20rem;
    z-index: 5;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
    box-shadow: null;
}

.menu.open {
    -webkit-transform: translateX(20rem);
            transform: translateX(20rem);
    box-shadow: 1rem 2rem 2rem 0 rgb(0, 0, 0, 0.75);
}

.menu > span {
    padding: 1rem;
    transition: all 0.3s;
}

.menu > span:hover {
    padding: 1rem;
    background-color: rgb(50, 50, 50);
}

.inner {
    max-width: 80rem;
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 5;
    height: 3rem;
    align-items: center;
    display: flex;
    box-shadow: 0 1rem 2rem 0 rgb(0, 0, 0, 0.75);
}

#logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#logo {
    width: 50rem;
    max-width: 100vw;
}

#menu {
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 1;
}

#menu > a {
    flex: 0 1 auto;
    width: 10rem;
    text-align: center;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
}

#about {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    text-align: center;
}

#about > * {
    max-width: 50rem;
    padding: 0.5rem;
}

.masonry {
    width: 100%;
    padding: 0.5rem;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
}

@media screen and (min-width: 993px) {
    .masonry {
        -webkit-column-count: 3;
                column-count: 3;
    }
}

@media screen and (max-width: 992px) {
    .masonry {
        -webkit-column-count: 2;
                column-count: 2;
    }
}

@media screen and (max-width: 600px) {
    .masonry {
        -webkit-column-count: 1;
                column-count: 1;
    }
}

.masonry div {
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
    display: inline-block;
}

.masonry img {
    box-shadow: 0 0.5rem 1rem 0 rgb(0, 0, 0, 0.75);
    width: 100%;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.masonry img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    height: 100%;
}
.hamburger:hover {
    opacity: 0.7;
}
.hamburger.is-active:hover {
    opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: rgb(226, 133, 152);
}

.hamburger-box {
    width: 30px;
    height: 16px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -4px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: rgb(226, 133, 152);
    border-radius: 3px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}
.hamburger-inner::before {
    top: -7px;
}
.hamburger-inner::after {
    bottom: -7px;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
    top: 2px;
}
.hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
    top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
            transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
            transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: rgb(31, 29, 32);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

